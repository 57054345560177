import React, { Fragment, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import CongressContext from '../CongressContext';
import Header from '../shared/Header';
import CurrentAppointment from '../appointments/CurrentAppointment';
import HorizontalSponsors from '../sponsors/HorizontalSponsors';
import MenuTiles from '../shared/MenuTiles';
import Sections from '../shared/Sections';
import useIntervalEffect from '../shared/useIntervalEffect';

const Congress = () => {
  const {congress, congressSlug, hasContests, workshops} = useContext(CongressContext);
  const {sectionSlug} = useParams();
  const pingPresence = () => fetch(`/${congressSlug}/${sectionSlug}/presence/`, { method: 'POST' })
  useEffect(() => pingPresence(), [])
  useIntervalEffect(pingPresence, 1000 * 60);

  return (
    <Fragment>
      <Header congressSlug={congressSlug}/>
      <div className="main-congress container light-text">
        <div className="d-md-flex justify-content-md-between mt-md-5 no-gutters">
          <div className="congress-wrapper px-5">
            <h2 className="h3 mt-5 pt-5">
              <strong>{congress.title}</strong>
            </h2>
            <h3 className="h3">{congress.excerpt}</h3>
            <Sections />
            <CurrentAppointment/>
          </div>
          <MenuTiles congressSlug={congressSlug} hasContests={hasContests} hasWorkshops={!isEmpty(workshops)}/>
        </div>
      </div>
      <HorizontalSponsors/>
    </Fragment>
  );
}

export default Congress;
